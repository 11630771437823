<template>
  <div :class="[stateStep, 'chip']">
    <div class="number-step">
      <div>
        {{ contentStep.step }}
      </div>
    </div>
    <div class="text-chip">
      {{ contentStep.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChipStepBusiness',
  props: {
    contentStep: {
      type: Object,
      default: () => {},
    },
    currentStep: {
      type: Number,
      default: null,
    },
  },
  computed: {
    stateStep() {
      if (this.contentStep.step === this.currentStep) return 'step-selected';
      if (this.contentStep.step < this.currentStep) return 'step-disable';
      if (this.contentStep.step > this.currentStep) return 'step-previous';
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
  .chip {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 1.2em;
    font-weight: 600;
    align-items: center;
    justify-content: flex-start;
    color: $color_primary;
    margin-top: 40px;
    .number-step {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid $color_primary;
      margin-right: 16px;
      width: 38px;
      height: 38px;
      border-radius: 100%;
      font-weight: bold;
    }
    &.step-previous {
      color: $subtitle_text_dashboard;
      .number-step {
        border-color: $subtitle_text_dashboard;
      }
    }
    &.step-disable {
      color: $subtitle_text_dashboard;
      .text-chip {
        text-decoration: line-through;
        text-decoration-color: $color_gray;
      }
      .number-step {
        border-color: $subtitle_text_dashboard;
      }
    }
    &.step-selected {
      color: $color_primary;
      .number-step {
        border-color: $color_primary;
      }
    }
  }
</style>
