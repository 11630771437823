<template>
  <div class="spatial-distribution">
    <div class="d-flex flex-row justify-space-between mb-4">
      <div class="title">
        {{ $t('components.register.business.mass_data.spatioal_distribution.title') }}
      </div>
      <div class="filters">
        <span class="flex-grow-1">
          {{ $t('components.register.business.mass_data.spatioal_distribution.subtitle') }}
        </span>
        <select
          id="select-states"
          v-model="state"
          class="select-states"
        >
          <option
            v-for="(stateAcronym, index) in states"
            :key="index"
            :value="stateAcronym"
          >
            {{ stateAcronym }}
          </option>
        </select>
      </div>
    </div>
    <div class="table">
      <div class="header">
        <span class="header-item">
          {{ $t('components.register.business.mass_data.spatioal_distribution.header.state') }}
        </span>
        <span class="header-item">
          {{ $t('components.register.business.mass_data.spatioal_distribution.header.paper') }}
        </span>
        <span class="header-item">
          {{ $t('components.register.business.mass_data.spatioal_distribution.header.plastic') }}
        </span>
        <span class="header-item">
          {{ $t('components.register.business.mass_data.spatioal_distribution.header.glass') }}
        </span>
        <span class="header-item">
          {{ $t('components.register.business.mass_data.spatioal_distribution.header.metal') }}
        </span>
      </div>
      <div class="body">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="row-body"
        >
          <v-btn
            class="remove-state"
            icon
            @click="removeState(index)"
          >
            <v-icon
              small
              color="red"
            >
              mdi-delete
            </v-icon>
          </v-btn>
          <span class="row-item font-weight-bold">
            {{ item.state }}
          </span>
          <span class="row-item percent">
            {{
              $t('components.register.business.mass_data.spatioal_distribution.percentage_items',
                 { percent: item.paperPercent })
            }}
          </span>
          <span class="row-item percent">
            {{
              $t('components.register.business.mass_data.spatioal_distribution.percentage_items',
                 { percent: item.plasticPercent })
            }}
          </span>
          <span class="row-item percent">
            {{
              $t('components.register.business.mass_data.spatioal_distribution.percentage_items',
                 { percent: item.glassPercent })
            }}
          </span>
          <span class="row-item percent">
            {{
              $t('components.register.business.mass_data.spatioal_distribution.percentage_items',
                 { percent: item.metalPercent })
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SpatialDistribution',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      state: '',
    };
  },
  computed: {
    ...mapGetters({
      states: 'getBrazilianStates',
    }),
  },
  watch: {
    state(value) {
      this.selectState(value);
    },
  },
  mounted() {
    this.searchState();
  },
  methods: {
    ...mapActions([
      'searchState',
    ]),
    selectState(state) {
      this.$emit('addState', state);
    },
    removeState(index) {
      this.$emit('removeState', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.spatial-distribution {
  background: $color_light;
  height: 500px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: $color_dark;
  .title {
    font-weight: 600;
    font-size: 1.2em;
    letter-spacing: 0px;
  }
  .filters {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .select-states {
      width: 120px;
      max-width: 300px;
      margin-left: 12px;
      border: 2px solid $color_black;
      border-radius: 10px;
      padding: 6px;
      font-weight: bold;

      background-image:
        linear-gradient(40deg, transparent 50%, $color_black 50%),
        linear-gradient(140deg, $color_black 50%, transparent 50%);
      background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
      background-repeat: no-repeat;
      &:focus {
        background-image:
          linear-gradient(138deg, transparent 50%, $color_primary 50%),
          linear-gradient(38deg, $color_primary 50%, transparent 50%);
        outline: none;
        border-color: $color_primary;
      }
    }
  }
  .table {
    .header {
      background: $dashboard_background;
      border-radius: 4px;
      padding: 12px;
      display: flex;
      justify-content: space-around;
      .header-item {
        font-weight: bold;
      }
    }
    .body {
      overflow-y: auto;
      height: 350px;
      .row-body {
        margin-top: 6px;
        background: $background_table;
        border-radius: 4px;
        padding: 12px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .remove-state {
          display: none;
        }
        .row-item {
          &.percent {
            background: $dashboard_background;
            border-radius: 24px;
            padding: 6px;
          }
        }
      }
      .row-body:hover {
        .remove-state {
          display: block;
        }
      }
    }
  }
}
</style>
