<template>
  <div class="form-offset-amount">
    <div class="title-field">
      {{ mountTranslate('title') }}
    </div>

    <div class="content-offset-amount">
      <ValidationProvider
        rules="required"
      >
        <div class="content-material">
          <div class="title-material">
            {{ mountTranslate('paper.title') }}
          </div>
          <div class="content-input">
            <input
              v-model="value.paper_percent"
              class="input-mass-data"
              :placeholder="mountTranslate('paper.placeholder')"
              @keyup="validateSize"
            >
            <div class="prefix">
              <span>{{ mountTranslate('prefix') }}</span>
            </div>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
      >
        <div class="content-material">
          <div class="title-material">
            {{ mountTranslate('plastic.title') }}
          </div>
          <div class="content-input">
            <input
              v-model="value.plastic_percent"
              class="input-mass-data"
              :placeholder="mountTranslate('plastic.placeholder')"
              @keyup="validateSize"
            >
            <div class="prefix">
              <span>{{ mountTranslate('prefix') }}</span>
            </div>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
      >
        <div class="content-material">
          <div class="title-material">
            {{ mountTranslate('glass.title') }}
          </div>
          <div class="content-input">
            <input
              v-model="value.glass_percent"
              class="input-mass-data"
              :placeholder="mountTranslate('glass.placeholder')"
              @keyup="validateSize"
            >
            <div class="prefix">
              <span>{{ mountTranslate('prefix') }}</span>
            </div>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider
        rules="required"
      >
        <div class="content-material">
          <div class="title-material">
            {{ mountTranslate('metal.title') }}
          </div>
          <div class="content-input">
            <input
              v-model="value.metal_percent"
              class="input-mass-data"
              :placeholder="mountTranslate('metal.placeholder')"
              @keyup="validateSize"
            >
            <div class="prefix">
              <span>{{ mountTranslate('prefix') }}</span>
            </div>
          </div>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FormOffsetAmount',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters([
      'getBusiness',
    ]),
  },
  methods: {
    mountTranslate(value) {
      return this.$t(`components.register.business.mass_data.labels.offset_amount.${value}`);
    },
    validateSize() {
      if (Number(this.value.paper_percent) > 100) this.value.paper_percent = 100;
      if (Number(this.value.glass_percent) > 100) this.value.glass_percent = 100;
      if (Number(this.value.metal_percent) > 100) this.value.metal_percent = 100;
      if (Number(this.value.plastic_percent) > 100) this.value.plastic_percent = 100;
    },
  },
};
</script>
<style lang="scss" scoped>
  .form-offset-amount {
    width: 100%;
    .title-field {
      color: $color_dark;
      font-size: 1.2em;
      font-weight: 600;
    }

    .content-offset-amount {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 16px;
      .content-material {
        width: 152px;
        .title-material {
          color: $color_text_card_kind;
          font-size: 1em;
          font-weight: 600;
          margin-bottom: 16px;
        }
        .content-input {
          display: flex;
          .prefix {
            height: 48px;
            width: 48px;
            text-align: center;
            background: $color_primary;
            color: $color_white;
            display: flex;
            align-items: center;
            padding: 0px 4px;
            border-radius: 0px 8px 8px 0px;
            justify-content: center;
            span {
              font-size: 1em;
              font-weight: bold;
            }
          }
          .input-mass-data {
            width: 100%;
            height: 48px;
            padding: 10px;
            border-radius: 8px 0px 0px 8px;
            background-color: $field_backgrond_primary;
            &:focus{
              outline: none;
              &::placeholder {
                color: transparent;
              }
            }
          }
        }
      }
    }
  }
</style>
