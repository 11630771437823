<template>
  <div class="identification-form text-start">
    <back-button
      :text="$t('components.identification.labels.btn_back')"
      @click="backStep"
    />
    <div class="subtitle-identification">
      {{ $t('components.identification.labels.identify') }}
    </div>

    <ValidationObserver
      v-slot="{ invalid }"
    >
      <v-form
        class="form-identification"
        @keyup.native.enter="executeLogin"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :name="$t('components.identification.field.name')"
          rules="required"
        >
          <div class="content-field">
            <div class="title-field">
              {{ $t('components.identification.labels.name') }}
            </div>
            <v-text-field
              id="input-register-1"
              v-model="name"
              required
              flat
              background-color="field_register"
              class="input-register"
              :label="$t('components.identification.placeholders.name')"
              solo
              :error-messages="errors"
            />
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-if="!isIndustryOrManagementEntity"
          v-slot="{ errors }"
          :name="$t('components.identification.field.role')"
          rules="required"
        >
          <div class="content-field">
            <div class="title-field">
              {{ $t('components.identification.labels.role') }}
            </div>
            <v-text-field
              id="input-register-2"
              v-model="role"
              required
              flat
              background-color="field_register"
              class="input-register"
              :label="$t('components.identification.placeholders.role')"
              solo
              :error-messages="errors"
            />
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          :name="$t('components.identification.field.email')"
          rules="required|email"
        >
          <div class="content-field">
            <div class="title-field">
              {{ $t('components.identification.labels.email') }}
            </div>
            <v-text-field
              id="input-register-3"
              v-model="email"
              required
              flat
              background-color="field_register"
              class="input-register"
              :label="$t('components.identification.placeholders.email')"
              type="email"
              solo
              :error-messages="errors"
            />
          </div>
        </ValidationProvider>
        <div
          v-if="!isIndustryOrManagementEntity"
          class="content-field"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('components.identification.field.phone')"
            rules="min:15|required"
          >
            <div class="title-field">
              {{ $t('components.identification.labels.phone') }}
            </div>
            <div>
              <v-text-field
                id="input-register-4"
                v-model="phone"
                v-mask="'(##) #####-####'"
                flat
                background-color="field_register"
                class="input-register"
                :label="$t('components.identification.placeholders.phone')"
                solo
                :error-messages="errors"
              />
            </div>
          </ValidationProvider>
        </div>
        <div
          v-else
          class="section-password"
        >
          <div class="content-password mr-9">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.identification.field.state')"
              rules="required"
            >
              <div class="title-field-password">
                {{ $t('components.identification.labels.state') }}
              </div>
              <v-text-field
                id="input-register-5"
                ref="state"
                v-model="state"
                name="state"
                flat
                background-color="field_register"
                class="input-password"
                :label="$t('components.identification.placeholders.state')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div class="content-password">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.identification.field.phone')"
              rules="min:15|required"
            >
              <div class="title-field-password">
                {{ $t('components.identification.labels.phone') }}
              </div>
              <v-text-field
                id="input-register-6"
                v-model="phone"
                v-mask="'(##) #####-####'"
                flat
                background-color="field_register"
                class="input-register"
                :label="$t('components.identification.placeholders.phone')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <ValidationObserver>
          <div class="section-password">
            <div class="content-password mr-9">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('components.identification.field.password')"
                rules="min:6|required"
                vid="password"
              >
                <div class="title-field-password">
                  {{ $t('components.identification.labels.password') }}
                </div>
                <v-text-field
                  id="input-register-5"
                  ref="password"
                  v-model="password"
                  name="password"
                  flat
                  background-color="field_register"
                  class="input-password"
                  :label="$t('components.identification.placeholders.password')"
                  type="password"
                  solo
                  :error-messages="errors"
                />
              </ValidationProvider>
            </div>
            <div class="content-password">
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('components.identification.field.confirm_password')"
                rules="confirmed:password|required"
              >
                <div class="title-field-password">
                  {{ $t('components.identification.labels.confirm_password') }}
                </div>
                <v-text-field
                  id="input-register-6"
                  v-model="confirmPassword"
                  flat
                  background-color="field_register"
                  class="input-password"
                  :label="$t('components.identification.placeholders.confirm_password')"
                  type="password"
                  solo
                  :error-messages="errors"
                />
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>

        <div class="content-terms">
          <ValidationProvider
            v-slot="{ errors }"
            name="terms"
            rules="required"
          >
            <div
              id="terms"
              class="term-and-policy"
            >
              <v-checkbox
                v-model="term"
                color="primary"
                value="success"
                hide-details
                :error-messages="errors"
              />
              <div class="label-terms">
                {{ $t('components.identification.labels.terms_of_use.label_one') }}
                <a
                  :href="termPdf"
                  class="link-term-policy"
                  download
                >
                  {{ $t('components.identification.labels.terms_of_use.label_two') }}
                </a>
                {{ $t('components.identification.labels.terms_of_use.label_three') }}
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            name="privacy"
            rules="required"
          >
            <div
              id="policy"
              class="term-and-policy"
            >
              <v-checkbox
                v-model="policy"
                color="primary"
                value="success"
                hide-details
                :error-messages="errors"
              />
              <div class="label-terms">
                {{ $t('components.identification.labels.privacy_policy.label_one') }}
                <a
                  :href="policyPdf"
                  class="link-term-policy"
                  download
                >
                  {{ $t('components.identification.labels.privacy_policy.label_two') }}
                </a>
                {{ $t('components.identification.labels.privacy_policy.label_three') }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="button-access">
          <rectoplus-button
            id="startLogin"
            :disabled="invalid"
            class="button-identification"
            :text="$t('components.identification.buttons.button')"
            @click="openDialogConfirm"
          />
        </div>
      </v-form>
    </ValidationObserver>
    <confirm-register
      v-model="dialog"
      @confirmationResponse="closeDialog"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import ConfirmRegister from '@/components/dialogs/ConfirmRegister.vue';

export default {
  name: 'IdentificationForm',
  components: {
    RectoplusButton,
    ConfirmRegister,
    BackButton,
  },
  data() {
    return {
      name: '',
      email: '',
      role: '',
      password: '',
      term: false,
      policy: false,
      confirmPassword: '',
      phone: '',
      confirmRegister: false,
      state: '',
      termPdf: '/termos_de_uso.pdf',
      policyPdf: '/politica_de_privacidade.pdf',
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(['getBusiness']),
    isIndustryOrManagementEntity() {
      return this.getBusiness.kind === 'industry_company' || this.getBusiness.kind === 'representative_business_industries';
    },
  },
  methods: {
    openDialogConfirm() {
      this.dialog = true;
    },
    closeDialog(value) {
      if (value) {
        const userData = {
          name: this.name,
          email: this.email,
          role: this.role,
          password: this.password,
          phone: this.phone,
          accepted_terms: this.term,
          accepted_privacy: this.policy,
          confirm_password: this.confirmPassword,
          state: this.state,
        };
        this.$emit('submited', userData);
      }
    },
    backStep() {
      this.$emit('backStep');
    },
  },
};
</script>
<style lang="scss" scoped>
  .identification-form {
    max-width: 660px;
    margin: 52px auto 44px auto;
    .subtitle-identification {
      color: $title_color_primary;
      font-size: 1.8em;
      font-weight: bold;
      margin-bottom: 32px;
      margin-top: 40px;
    }
    .form-identification {
      .content-field {
        margin-bottom: 4px;
        .title-field {
          color: $color_title_field;
          font-size: 1em;
          font-weight: bold;
          margin-bottom: 16px;
        }
        .input-register {
          border-radius: 8px;
        }
      }
      .section-password {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .content-password {
          width: 100%;
          .title-field-password {
            color: $color_title_field;
            font-size: 1em;
            font-weight: bold;
            margin-bottom: 16px;
          }
          .input-password {
            width: 100%;
            border-radius: 8px;
          }
        }
      }
      .content-terms {
        margin-top: 16px;
        .term-and-policy {
          display: flex;
          margin-bottom: 20px;
          align-items: flex-end;
          color: $color_text_card_kind;
          font-size: 1em;
          .label-terms {
            font-size: 1em;
            .link-term-policy {
              color: $color_text_card_kind;
            }
          }
        }
      }
      .button-access{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 64px;
        .button-identification {
          width: 100%;
          height: 70px;
        }
      }
    }
  }
  @media (max-width: $sm) {
    .identification-form {
      padding: 0 20px;
      .form-identification {
        .section-password {
          display: block;
        }
        .content-terms {
          .term-and-policy {
            align-items: center;
          }
        }
      }
    }
  }
</style>
