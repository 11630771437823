<template>
  <div class="conclusion">
    <h1 class="title-conclusion">
      {{ mountTranslate('title') }}
    </h1>
    <p class="subtitle">
      {{ mountTranslate('subtitle') }}
    </p>
    <div class="packaging-market">
      <p class="title">
        {{ mountTranslate('packaging_market.title') }}
      </p>
      <div class="types-material">
        <div class="type">
          <span class="text">
            {{ mountTranslate('packaging_market.types_material.paper') }}
          </span>
          <span class="amount">
            {{
              mountTranslate('packaging_market.amount', {
                amount: getMassData.paper_compensated
              })
            }}
          </span>
        </div>
        <div class="type">
          <span class="text">
            {{ mountTranslate('packaging_market.types_material.plastic') }}
          </span>
          <span class="amount">
            {{
              mountTranslate('packaging_market.amount', {
                amount: getMassData.plastic_compensated
              })
            }}
          </span>
        </div>
        <div class="type">
          <span class="text">
            {{ mountTranslate('packaging_market.types_material.glass') }}
          </span>
          <span class="amount">
            {{
              mountTranslate('packaging_market.amount', {
                amount: getMassData.glass_compensated
              })
            }}
          </span>
        </div>
        <div class="type">
          <span class="text">
            {{ mountTranslate('packaging_market.types_material.metal') }}
          </span>
          <span class="amount">
            {{
              mountTranslate('packaging_market.amount', {
                amount: getMassData.metal_compensated
              })
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="amount-compensated">
      <p class="title">
        {{ mountTranslate('amount_compensated.title') }}
      </p>
      <div class="types-material">
        <div class="type">
          <span class="text">
            {{ mountTranslate('amount_compensated.types_material.paper') }}
          </span>
          <span class="amount">
            {{
              mountTranslate('amount_compensated.percentage_items', {
                percent: getMassData.paper_quantity
              })
            }}
          </span>
        </div>
        <div class="type">
          <span class="text">
            {{ mountTranslate('amount_compensated.types_material.plastic') }}
          </span>
          <span class="amount">
            {{
              mountTranslate('amount_compensated.percentage_items', {
                percent: getMassData.plastic_quantity
              })
            }}
          </span>
        </div>
        <div class="type">
          <span class="text">
            {{ mountTranslate('amount_compensated.types_material.glass') }}
          </span>
          <span class="amount">
            {{
              mountTranslate('amount_compensated.percentage_items', {
                percent: getMassData.glass_quantity
              })
            }}
          </span>
        </div>
        <div class="type">
          <span class="text">
            {{ mountTranslate('amount_compensated.types_material.metal') }}
          </span>
          <span class="amount">
            {{
              mountTranslate('amount_compensated.percentage_items', {
                percent: getMassData.metal_quantity
              })
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="access-dashboard">
      <p class="title">
        {{ mountTranslate('access_dashboard.title') }}
      </p>
      <rectoplus-button
        id="nextStep3=4"
        class="button-access"
        :text="mountTranslate('access_dashboard.button')"
        @click="toDashboard()"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'ConclusionIndustry',
  components: {
    RectoplusButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'getMassData',
    ]),
  },
  methods: {
    toDashboard() {
      this.$router.push({
        name: 'home',
      });
    },
    mountTranslate(value, params = null) {
      return this.$t(`components.register.business.conclusion_industry.${value}`, params);
    },
  },
};
</script>

<style lang="scss" scoped>
.conclusion {
  max-width: 660px;
  margin: 80px auto 20px auto;
  .title-conclusion {
    font-weight: bold;
    font-size: 1.8rem;
  }
  .subtitle {
    color: $color_gray;
  }
  .packaging-market, .amount-compensated {
    .title {
      font-weight: bold;
    }
    .types-material {
      display: flex;
      .type {
        display: flex;
        flex-direction: column;
        margin-right: 28px;
        .text {
          color: $color_gray;
        }
        .amount {
          color: $color_dark;
          font-weight: 600;
        }
      }
    }
  }
  .packaging-market {
    margin-top: 28px;
  }
  .amount-compensated {
    margin-top: 48px;
  }
  .access-dashboard {
    margin-top: 48px;
    .title {
      font-weight: bold;
    }
    .button-access {
      width: 260px;
      height: 70px;
    }
  }
}
</style>
