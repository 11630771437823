<template>
  <div class="organization-form">
    <ValidationObserver
      v-slot="{ invalid }"
    >
      <v-form>
        <ValidationProvider
          v-slot="{ errors }"
          :name="mountTranslate('labels.validates_name.cnpj')"
          rules="min:18|required"
        >
          <div class="title-field">
            {{ mountTranslate('labels.cnpj') }}
          </div>
          <v-text-field
            v-model="business.cnpj"
            v-mask="'##.###.###/####-##'"
            required
            flat
            background-color="field_register"
            class="input-register"
            :label="mountTranslate('placeholder.cnpj')"
            solo
            :error-messages="errors"
          />
        </ValidationProvider>
        <div
          v-show="getBusiness.kind !== 'industry_company'"
          class="content-flex"
        >
          <div class="content-field mr-9">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.state_registration')"
              rules="min:9"
            >
              <div class="title-field">
                {{ mountTranslate('labels.state_registration') }}
              </div>
              <v-text-field
                v-model="business.state_registry"
                v-mask="'#############'"
                flat
                background-color="field_register"
                class="input-subscription"
                :label="mountTranslate('placeholder.state_regis')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>

          <div class="content-field">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.municipal_registration')"
              rules="min:7"
            >
              <div class="title-field">
                {{ mountTranslate('labels.municipal_registration') }}
              </div>
              <v-text-field
                v-model="business.city_registry"
                v-mask="'##############'"
                flat
                background-color="field_register"
                class="input-subscription"
                :label="mountTranslate('labels.municipal_registration')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <div class="content-flex">
          <div class="content-field mr-9">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.cep')"
              rules="min:9|required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.cep') }}
              </div>
              <div class="d-flex">
                <v-text-field
                  v-model="business.zip_code"
                  v-mask="'#####-###'"
                  required
                  append-icon="mdi-magnify"
                  flat
                  background-color="field_register"
                  class="input-register"
                  :label="mountTranslate('placeholder.cep')"
                  solo
                  :error-messages="errors"
                  @blur="searchCep"
                />
              </div>
            </ValidationProvider>
          </div>
          <div class="content-field">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.state')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.state') }}
              </div>
              <v-text-field
                v-model="business.state"
                flat
                background-color="field_register"
                class="input-subscription"
                :label="mountTranslate('placeholder.state')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <div class="content-flex">
          <div class="content-field mr-9">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.city')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.city') }}
              </div>
              <v-text-field
                v-model="business.city"
                required
                flat
                background-color="field_register"
                class="input-subscription"
                :label="mountTranslate('placeholder.city')"
                type="text"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div class="content-field">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.neighbourhood')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.neighbourhood') }}
              </div>
              <v-text-field
                v-model="business.neighbourhood"
                flat
                background-color="field_register"
                class="input-subscription"
                :label="mountTranslate('placeholder.neighbourhood')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <div class="content-flex">
          <div class="content-field-street mr-9">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.street')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.street') }}
              </div>
              <v-text-field
                v-model="business.street"
                flat
                background-color="field_register"
                class="input-subscription"
                :label="mountTranslate('placeholder.street')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>

          <div class="content-field-number">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.number')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.number') }}
              </div>
              <v-text-field
                v-model="business.number"
                flat
                type="number"
                background-color="field_register"
                class="input-subscription"
                :label="mountTranslate('placeholder.number')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <div class="content-flex">
          <div class="content-field">
            <div class="title-field">
              {{ mountTranslate('labels.complement') }}
            </div>
            <v-text-field
              v-model="business.complement"
              flat
              background-color="field_register"
              class="input-subscription"
              :label="mountTranslate('placeholder.complement')"
              solo
            />
          </div>
        </div>

        <div
          v-if="getBusiness.kind === 'industry_company'"
          class="content-flex"
        >
          <div class="content-field">
            <div class="title-field">
              {{ mountTranslate('branch.label') }}
            </div>
            <div
              class="add-branch-title"
              @click="openBranchDialog()"
            >
              {{ mountTranslate('branch.add_branch') }}
            </div>
          </div>
        </div>
        <div class="button-access">
          <rectoplus-button
            id="nextStep2"
            :disabled="invalid"
            class="button-identification"
            :text="mountTranslate('button')"
            @click="doRegisterBusiness"
          />
        </div>
      </v-form>
    </ValidationObserver>
    <branch-dialog
      v-model="openDialog"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BranchDialog from '@/components/dialogs/BranchDialog.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'OrganizationForm',
  components: {
    RectoplusButton,
    BranchDialog,
  },
  data() {
    return {
      business: {
        name: '',
        number: '',
        neighbourhood: '',
        zip_code: '',
        street: '',
        cnpj: '',
        city: '',
        state: '',
        city_registry: '',
        state_registry: '',
        complement: '',
        kind: '',
        branch: [],
      },
      openDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'getLocationBusiness',
      'getBusiness',
    ]),
  },
  watch: {
    getLocationBusiness(value) {
      this.business.city = value.localidade;
      this.business.state = value.uf;
      this.business.street = value.logradouro;
      this.business.neighbourhood = value.bairro;
    },
  },
  methods: {
    ...mapActions([
      'searchBusinessZipCode',
    ]),
    searchCep() {
      this.searchBusinessZipCode(this.business.zip_code);
    },
    doRegisterBusiness() {
      this.business.kind = this.getBusiness?.kind;
      if (this.getBusiness?.branch) this.business.branch = [...this.getBusiness.branch];
      this.$emit('submitedBusiness', this.business);
    },
    mountTranslate(value) {
      return this.$t(`components.register.business.organization.${value}`);
    },
    openBranchDialog() {
      this.openDialog = true;
    },
    closeDialog() {
      this.openDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
  .organization-form {
    width: 100%;
    .content-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .content-field {
        width: 100%;
        .add-branch-title {
          margin-top: 10px;
          color: $color_primary;
          font-size: 1.2em;
          font-weight: bold;
          cursor: pointer;
        }
        .input-subscription {
          width: 100%;
          border-radius: 0.5em
        }
      }
      .content-field-street {
        width: 70%;
        .input-subscription {
          width: 100%;
          border-radius: 0.5em
        }
      }
      .content-field-number {
        width: 30%;
        .input-subscription {
          width: 100%;
          border-radius: 0.5em
        }
      }
    }
    .title-field {
      color: $color_text_card_kind;
      font-size: 1em;
      font-weight: bold;
    }
    .button-access{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 32px;
      .button-identification {
        width: 100%;
        height: 70px;
      }
    }
    .input-register{
      border-radius: 8px;
    }
  }
</style>
