<template>
  <div class="mass-data">
    <div class="content-mass-data">
      <div class="subtitle-text">
        {{ $t('components.stepper_business.steps.mass_data') }}
      </div>
      <ValidationObserver
        v-slot="{ invalid }"
      >
        <form-total-amount
          v-model="totalAmount"
          class="mb-10"
        />
        <form-offset-amount
          v-model="offsetAmount"
          class="mb-10"
        />
        <spatial-distribution
          :items="items"
          @addState="addStateForSpatialDistribution"
          @removeState="removeStateForSpatialDistribution"
        />
        <div class="button-access">
          <rectoplus-button
            id="nextStep3"
            :disabled="invalid || !hasDistributionSpatial"
            class="button-identification"
            :text="$t('components.register.business.mass_data.button')"
            @click="sendMassData"
          />
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SpatialDistribution from '@/components/tables/SpatialDistribution.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import FormTotalAmount from '@/components/forms/register/business/FormTotalAmount.vue';
import FormOffsetAmount from '@/components/forms/register/business/FormOffsetAmount.vue';

export default {
  name: 'MassData',
  components: {
    SpatialDistribution,
    RectoplusButton,
    FormTotalAmount,
    FormOffsetAmount,
  },
  data() {
    return {
      totalAmount: {
        plastic: null,
        paper: null,
        glass: null,
        metal: null,
      },
      offsetAmount: {
        plastic_percent: null,
        glass_percent: null,
        paper_percent: null,
        metal_percent: null,
      },
      states: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters([
      'getBusiness',
    ]),
    hasDistributionSpatial() {
      return this.items.length > 0;
    },
  },
  methods: {
    addStateForSpatialDistribution(state) {
      const hasState = this.states.find((item) => item === state);
      if (hasState) return;
      this.states.push(state);
      const material = this.calculateSpatialDistribution();
      this.items = [];
      this.states.forEach((item) => {
        this.items.push({
          state: item,
          ...material,
        });
      });
    },
    removeStateForSpatialDistribution(index) {
      this.states.splice(index, 1);
      const material = this.calculateSpatialDistribution();
      this.items = [];
      this.states.forEach((item) => {
        this.items.push({
          state: item,
          ...material,
        });
      });
    },
    calculateSpatialDistribution() {
      const glassPercent = (this.offsetAmount.glass_percent / this.states.length).toFixed(2);
      const plasticPercent = (this.offsetAmount.plastic_percent / this.states.length).toFixed(2);
      const paperPercent = (this.offsetAmount.paper_percent / this.states.length).toFixed(2);
      const metalPercent = (this.offsetAmount.metal_percent / this.states.length).toFixed(2);
      return {
        glassPercent,
        plasticPercent,
        paperPercent,
        metalPercent,
      };
    },
    sendMassData() {
      const spatialDistribution = this.items.map((item) => ({
        state: item.state,
        glass_percent: item.glassPercent,
        paper_percent: item.paperPercent,
        metal_percent: item.metalPercent,
        plastic_percent: item.plasticPercent,
      }));
      const massDataParams = {
        plastic_compensated: this.totalAmount.plastic,
        glass_compensated: this.totalAmount.glass,
        paper_compensated: this.totalAmount.paper,
        metal_compensated: this.totalAmount.metal,
        glass_quantity: this.offsetAmount.glass_percent,
        paper_quantity: this.offsetAmount.paper_percent,
        metal_quantity: this.offsetAmount.metal_percent,
        plastic_quantity: this.offsetAmount.plastic_percent,
        spatial_distribution: spatialDistribution,
        business_id: this.getBusiness.id,
      };
      this.$emit('sendMassData', massDataParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.mass-data {
  padding: 0px 20px;
  .content-mass-data {
    max-width: 660px;
    margin: 80px auto 20px auto;
    .subtitle-text {
      color: $title_color_primary;
      margin-bottom: 32px;
      font-size: 1.8em;
      font-weight: bold;
    }
    .button-access{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 64px;
      .button-identification {
        width: 100%;
        height: 70px;
      }
    }
  }
}
@media (max-width: 1100px) {
  .data-business {
    display: flex;
    justify-content: center;
    .content-business {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
</style>
