<template>
  <div class="stepper-register">
    <div
      v-if="!registerBusiness"
      class="side-image"
    >
      <v-img
        :src="backgroundImage"
        class="img-register"
      />
    </div>
    <div
      v-else
      class="side-box"
    >
      <div
        class="chip-steps"
      >
        <div>
          <chip-step-register
            v-for="chip in registerSteps"
            :key="chip.step"
            :content-step="chip"
            :current-step="getStepUser"
          />
        </div>
      </div>
    </div>
    <div class="content-stepper">
      <v-stepper
        v-model="getStepUser"
        flat
        tile
        class="stepper"
      >
        <v-stepper-items class="pa-0">
          <v-stepper-content
            class="pa-0"
            step="0"
          >
            <div class="scroll">
              <business-kind @nextStep="nextStep" />
            </div>
          </v-stepper-content>

          <v-stepper-content
            class="pa-0"
            step="1"
          >
            <identification-form
              @submited="register"
              @backStep="backSteps"
            />
          </v-stepper-content>

          <!-- cadastro de empresa -->
          <v-stepper-content
            step="2"
          >
            <data-organization
              @registerDataBusiness="registerDataOrganization"
            />
          </v-stepper-content>

          <v-stepper-content
            v-if="!isIndustry"
            step="3"
            class="organization"
          >
            <documents-organization
              @submitDocuments="registerDocumentsOrganization"
            />
          </v-stepper-content>

          <v-stepper-content
            v-else
            step="3"
            class="organization"
          >
            <mass-data
              @sendMassData="registerMassDataOrganization"
            />
          </v-stepper-content>

          <v-stepper-content
            step="4"
          >
            <conclusion-industry />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import IdentificationForm from '@/components/forms/register/IdentificationForm.vue';
import BusinessKind from '@/components/forms/register/business/BusinessKind.vue';
import MassData from '@/components/cards/business/MassData.vue';
import ConclusionIndustry from '@/components/cards/business/ConclusionIndustry.vue';
import ChipStepRegister from '@/components/stepps/ChipStepRegister.vue';
import DataOrganization from '@/components/cards/business/DataBusiness.vue';
import DocumentsOrganization from '@/components/cards/business/DocumentsBusiness.vue';
import imgBottles from '@/assets/register/garrafas.png';
import imgCaps from '@/assets/register/tampinhas.jpg';

export default {
  name: 'Steppers',
  components: {
    IdentificationForm,
    BusinessKind,
    ChipStepRegister,
    DocumentsOrganization,
    DataOrganization,
    MassData,
    ConclusionIndustry,
  },
  data() {
    return {
      step: 1,
      user: {
        name: '',
        job: '',
        email: '',
        telepohone: '',
        password: '',
      },
      logisticOperatorSteps: [
        { step: 1, title: this.$t('components.stepper_business.steps.identification') },
        { step: 2, title: this.$t('components.stepper_business.steps.business_data') },
        { step: 3, title: this.$t('components.stepper_business.steps.documents') },
        // { step: 4, title: this.$t('components.stepper_business.steps.operational_capacity') },
      ],
      representativeSteps: [
        { step: 1, title: this.$t('components.stepper_business.steps.identification') },
        { step: 2, title: this.$t('components.stepper_business.steps.business_data') },
      ],
      industrySteps: [
        { step: 1, title: this.$t('components.stepper_business.steps.identification') },
        { step: 2, title: this.$t('components.stepper_business.steps.business_data') },
        { step: 3, title: this.$t('components.stepper_business.steps.mass_data') },
        { step: 4, title: this.$t('components.stepper_business.steps.conclusion') },
      ],
      caps: imgCaps,
      bottles: imgBottles,
    };
  },
  computed: {
    ...mapGetters([
      'getStepUser',
      'getBusiness',
    ]),
    isRepresentative() {
      return this.getBusiness.kind === 'representative_business_industries';
    },
    isIndustry() {
      return this.getBusiness.kind === 'industry_company';
    },
    backgroundImage() {
      if (this.getStepUser === 1) return this.caps;
      if (this.getStepUser === 0) return this.bottles;
      return '';
    },
    registerBusiness() {
      return this.getStepUser >= 2;
    },
    registerSteps() {
      if (this.isRepresentative) return this.representativeSteps;
      if (this.isIndustry) return this.industrySteps;
      return this.logisticOperatorSteps;
    },
  },
  watch: {
    getStepUser(value) {
      if (this.isRepresentative && value === 3) this.$router.push({ name: 'home' });
      if (this.isIndustry && value === 5) this.$router.push({ name: 'home' });
      if (!this.isIndustry && value === 4) this.$router.push({ name: 'home' });
    },
  },
  mounted() {
    this.setStepUser();
  },
  methods: {
    ...mapActions([
      'setStepUser',
      'initLoading',
      'setKindBusiness',
      'registerOrganization',
      'sendDocumentsFiles',
      'nextStepUser',
      'backStepUser',
      'registerMassData',
    ]),
    nextStep(payload) {
      this.setKindBusiness(payload);
      this.nextStepUser();
    },
    backSteps() {
      this.backStepUser();
    },
    register(payload) {
      this.$emit('submited', payload);
    },
    registerDataOrganization(payload) {
      this.initLoading();
      this.registerOrganization(payload);
    },
    registerDocumentsOrganization(payload) {
      this.initLoading();
      this.sendDocumentsFiles(payload);
    },
    registerMassDataOrganization(payload) {
      this.initLoading();
      this.registerMassData(payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.stepper-register{
  display: flex;
  height: 100vh;
  .content-stepper {
    width: 100%;
    overflow: auto;
    .stepper {
      background: transparent;
      .scroll {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .organization {
          margin-top: 124px;
        }
      }
    }
  }
  .side-image {
    width: 40%;
    background-color: $step_register_campany;
    .img-register {
      height: 100%;
    }
  }
  .side-box {
    width: 50%;
    .chip-steps {
      height: 100vh;
      background-color: #fafcf7;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }
}
@media (max-width: 1100px) {
  .stepper-register {
    display: flex;
    justify-content: center;
    .side-box, .side-image {
      display: none;
    }
  }
}
</style>
