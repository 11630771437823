<template>
  <div class="data-business">
    <div class="content-business">
      <div class="subtitle-text">
        {{ $t('components.stepper_business.steps.business_data') }}
      </div>
      <form-organization
        @submitedBusiness="submited"
      />
    </div>
  </div>
</template>
<script>
import FormOrganization from '@/components/forms/register/business/FormOrganization.vue';

export default {
  components: {
    FormOrganization,
  },
  methods: {
    submited(payload) {
      this.$emit('registerDataBusiness', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-business {
  .content-business {
    max-width: 660px;
    margin: 80px auto 20px auto;
    .subtitle-text {
      color: $title_color_primary;
      margin-bottom: 32px;
      font-size: 1.8em;
      font-weight: bold;
    }
  }
}
@media (max-width: 1100px) {
  .data-business {
    display: flex;
    justify-content: center;
    .content-business {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
</style>
