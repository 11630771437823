<template>
  <v-col
    v-show="dialog"
    cols="auto"
  >
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="1280"
      :change="closeDialog()"
    >
      <div class="card-dialog">
        <v-row>
          <v-col>
            <form-branch
              :branch-for-edit="branchForEdit"
              @submitedBranch="setBranch"
            />
          </v-col>

          <v-col>
            <div>
              <div>
                <div class="add-branches-title">
                  {{ $t('components.dialogs.branch.queue.title') }}
                </div>
                <div class="added-branches">
                  <div
                    v-if="hasBranch"
                    class="branches-in-queue"
                  >
                    <branch-name
                      v-for="(branch, index) in getBranch"
                      :key="index"
                      :branch-index="index"
                      :cnpj="branch.cnpj"
                      @delete="deleteBranch"
                      @edit="searchBranchForEdit"
                    />
                  </div>
                  <div
                    v-else
                    class="empty-branch"
                  >
                    {{ $t('components.dialogs.branch.queue.not_branches') }}
                  </div>
                </div>
                <div class="branches-buttons">
                  <button
                    class="cancel-button"
                    @click="cleanBranch()"
                  >
                    {{ $t('components.dialogs.branch.buttons.cancel') }}
                  </button>
                  <rectoplus-button
                    :text="$t('components.dialogs.branch.buttons.save')"
                    class="save-button"
                    @click="saveBranch"
                  />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FormBranch from '@/components/forms/register/business/branch/FormBranch.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import BranchName from '@/components/inputs/business/BranchName.vue';

export default {
  name: 'BranchDialog',
  components: {
    FormBranch,
    RectoplusButton,
    BranchName,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      branchForEdit: {
        name: '',
        street_number: '',
        neighbourhood: '',
        zip_code: '',
        street: '',
        cnpj: '',
        city: '',
        state: '',
        complement: '',
      },
      indexBranch: null,
      edit: false,
    };
  },
  computed: {
    ...mapGetters([
      'getBranch',
      'getBranchForEdit',
    ]),
    hasBranch() {
      return this.getBranch.length > 0;
    },
  },
  watch: {
    value(value) {
      this.dialog = value;
    },
    getBranchForEdit(value) {
      if (value) {
        this.branchForEdit = { ...value };
      }
    },
  },
  methods: {
    ...mapActions([
      'addBranch',
      'removBranch',
      'searchBranch',
      'editBranch',
      'setBranchInbusiness',
      'removeAllBranches',
    ]),
    closeDialog() {
      this.$emit('change', this.dialog);
    },
    setBranch(payload) {
      if (this.edit) {
        this.editBranch({ branch: payload, index: this.indexBranch });
        this.edit = false;
        this.indexBranch = null;
      } else {
        this.addBranch(payload);
      }
    },
    saveBranch() {
      if (this.getBranch.length > 0) {
        this.setBranchInbusiness(this.getBranch);
        this.dialog = false;
      }
    },
    cleanBranch() {
      if (this.getBranch.length > 0) {
        this.removeAllBranches(this.getBranch);
      }
      this.dialog = false;
    },
    deleteBranch(payload) {
      this.removBranch(payload);
    },
    searchBranchForEdit(payload) {
      this.edit = true;
      this.indexBranch = payload;
      this.searchBranch(payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  background: $color_light;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 42px;

  .add-branches-title {
    margin-top: 56px;
    color: $color_dark;
    font-weight: bold;
    font-size: 1.2em;
  }

  .added-branches {
    margin-top: 6px;
    border-radius: 10px;
    background-color: #ECF0E6;
    height: 390px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    .empty-branch {
      color: $color_dark;
      font-weight: bold;
      font-size: 1.2em;
    }
    .branches-in-queue {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      padding: 10px 24px 32px 24px;
    }
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: $files_backgrond;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $color_primary;
    border-radius: 20px;
    border: 0.1875em solid $files_backgrond;
  }

  .branches-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 42px;
    font-size: 1em;
    font-weight: bold;

    .save-button {
      width: 140px;
      height: 60px;
      margin-left: 10px;
      font-size: 1em;
      font-weight: bold;
    }

    .cancel-button {
      margin-right: 10px;
      width: 140px;
      height: 60px;
      color: $color_primary;
      background-color: $color_light;
      border: 2px solid $color_primary;
      border-radius: 10px;
    }
  }
}
</style>
