<template>
  <div class="branch-form">
    <ValidationObserver
      v-slot="{ invalid }"
    >
      <div class="branch-title">
        {{ mountTranslate('title') }}
      </div>
      <v-form>
        <ValidationProvider
          v-slot="{ errors }"
          :name="mountTranslate('labels.validates_name.cnpj')"
          rules="min:18|required"
        >
          <div class="title-field">
            {{ mountTranslate('labels.cnpj') }}
          </div>
          <v-text-field
            v-model="branch.cnpj"
            v-mask="'##.###.###/####-##'"
            required
            flat
            background-color="branch_field"
            class="input-register"
            :label="mountTranslate('placeholder.cnpj')"
            solo
            :error-messages="errors"
          />
        </ValidationProvider>

        <div class="content-flex">
          <div class="content-field mr-9">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.cep')"
              rules="min:9|required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.cep') }}
              </div>
              <div class="d-flex">
                <v-text-field
                  v-model="branch.zip_code"
                  v-mask="'#####-###'"
                  required
                  append-icon="mdi-magnify"
                  flat
                  background-color="branch_field"
                  class="input-register"
                  :label="mountTranslate('placeholder.cep')"
                  solo
                  :error-messages="errors"
                  @blur="searchCep"
                />
              </div>
            </ValidationProvider>
          </div>
          <div class="content-field">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.state')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.state') }}
              </div>
              <v-text-field
                v-model="branch.state"
                flat
                background-color="branch_field"
                class="input-subscription"
                :label="mountTranslate('placeholder.state')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <div class="content-flex">
          <div class="content-field mr-9">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.city')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.city') }}
              </div>
              <v-text-field
                v-model="branch.city"
                required
                flat
                background-color="branch_field"
                class="input-subscription"
                :label="mountTranslate('placeholder.city')"
                type="text"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
          <div class="content-field">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.neighbourhood')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.neighbourhood') }}
              </div>
              <v-text-field
                v-model="branch.neighbourhood"
                flat
                background-color="branch_field"
                class="input-subscription"
                :label="mountTranslate('placeholder.neighbourhood')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <div class="content-flex">
          <div class="content-field-street mr-9">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.street')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.street') }}
              </div>
              <v-text-field
                v-model="branch.street"
                flat
                background-color="branch_field"
                class="input-subscription"
                :label="mountTranslate('placeholder.street')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>

          <div class="content-field-number">
            <ValidationProvider
              v-slot="{ errors }"
              :name="mountTranslate('labels.validates_name.number')"
              rules="required"
            >
              <div class="title-field">
                {{ mountTranslate('labels.number') }}
              </div>
              <v-text-field
                v-model="branch.street_number"
                flat
                type="number"
                background-color="branch_field"
                class="input-subscription"
                :label="mountTranslate('placeholder.number')"
                solo
                :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <div class="content-flex">
          <div class="content-field">
            <div class="title-field">
              {{ mountTranslate('labels.complement') }}
            </div>
            <v-text-field
              v-model="branch.complement"
              flat
              background-color="branch_field"
              class="input-subscription"
              :label="mountTranslate('placeholder.complement')"
              solo
            />
          </div>
        </div>

        <div class="button-access">
          <rectoplus-button
            id="add-branch"
            :disabled="invalid"
            class="button-identification"
            :text="mountTranslate('button')"
            @click="doRegisterbranch"
          />
        </div>
      </v-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'FormBranch',
  components: {
    RectoplusButton,
  },
  props: {
    branchForEdit: {
      type: Object,
      default: () => {},
    },
    indexBranch: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      branch: {
        name: '',
        cnpj: '',
        state: '',
        city: '',
        zip_code: '',
        neighbourhood: '',
        street_number: '',
        complement: '',
        street: '',
      },
      openDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'getLocationBranch',
    ]),
  },
  watch: {
    getLocationBranch(value) {
      this.branch.city = value.localidade;
      this.branch.state = value.uf;
      this.branch.street = value.logradouro;
      this.branch.neighbourhood = value.bairro;
    },
    branchForEdit(value) {
      if (value) {
        this.branch = { ...value };
      }
    },
  },
  methods: {
    ...mapActions([
      'searchBranchZipCode',
    ]),
    searchCep() {
      this.searchBranchZipCode(this.branch.zip_code);
    },
    doRegisterbranch() {
      const branch = { ...this.branch };
      this.$emit('submitedBranch', branch);
      this.cleanForm();
    },
    cleanForm() {
      this.branch = {
        name: '',
        street_number: '',
        neighbourhood: '',
        zip_code: '',
        street: '',
        cnpj: '',
        city: '',
        state: '',
        complement: '',
      };
    },
    mountTranslate(value) {
      return this.$t(`components.register.business.branch.${value}`);
    },
  },
};
</script>
<style lang="scss" scoped>
  .branch-form {
    .branch-title {
      color: $color_dark;
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 40px;
    }
    .content-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .content-field {
        width: 100%;
        .add-branch-title {
          margin-top: 10px;
          color: $color_primary;
          font-size: 1.2em;
          font-weight: bold;
          cursor: pointer;
        }
        .input-subscription {
          width: 100%;
          border-radius: 0.5em
        }
      }
      .content-field-street {
        width: 70%;
        .input-subscription {
          width: 100%;
          border-radius: 0.5em
        }
      }
      .content-field-number {
        width: 30%;
        .input-subscription {
          width: 100%;
          border-radius: 0.5em
        }
      }
    }
    .title-field {
      color: $color_text_card_kind;
      font-size: 1em;
      font-weight: bold;
    }
    .button-access{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 32px;
      .button-identification {
        width: 50%;
        height: 70px;
      }
    }
    .input-register{
      border-radius: 8px;
    }
  }
</style>
