<template>
  <div class="branch">
    <div class="branch-name">
      {{ $t('components.dialogs.branch.branch_name', { cnpj: cnpj }) }}
    </div>
    <div class="branch-action">
      <v-btn
        icon
        @click="editBranch"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="deleteBranch"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BranchName',
  props: {
    branchIndex: {
      type: Number,
      default: null,
    },
    cnpj: {
      type: String,
      default: '',
    },
  },
  methods: {
    deleteBranch() {
      this.$emit('delete', this.branchIndex);
    },
    editBranch() {
      this.$emit('edit', this.branchIndex);
    },
  },
};
</script>
<style lang="scss" scoped>
.branch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  .branch-name {
    margin-top: 32px;
    font-size: 1.2em;
    font-weight: 400;
    color: $color_secondary;
  }
}
</style>
