<template>
  <div class="business-documents">
    <p class="subtitle-text">
      {{ $t('components.stepper_business.steps.documents') }}
    </p>
    <form-documents
      @submited="register"
    />
  </div>
</template>
<script>
import FormDocuments from '@/components/forms/register/business/FormDocuments.vue';

export default {
  components: {
    FormDocuments,
  },
  methods: {
    register(payload) {
      this.$emit('submitDocuments', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.business-documents {
  max-width: 660px;
  margin: 80px auto 20px auto;
  padding: 0px 20px;
  .subtitle-text {
    color: $title_color_primary;
    margin-bottom: 32px;
    font-size: 1.8em;
    font-weight: bold;
  }
}
@media (max-width: 1100px) {
  .business-documents {
    margin: 120px auto 20px auto;
  }
}
</style>
