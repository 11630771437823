<template>
  <div class="register">
    <steppers
      class="steps"
      @submited="register"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Steppers from '@/components/stepps/SteppersRegister.vue';

export default {
  name: 'Register',
  components: {
    Steppers,
  },
  computed: {
    ...mapGetters([
      'hasSession',
      'hasBusiness',
    ]),
  },
  mounted() {
    if (this.hasSession && this.hasBusiness) this.$router.push('/home');
  },
  methods: {
    ...mapActions([
      'initLoading',
    ]),
    register(params) {
      this.initLoading();
      this.$store.dispatch('register', params);
    },
  },
};
</script>
