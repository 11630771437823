<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600"
      :change="closeDialog()"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('components.confirm_register.title') }}
        </v-card-title>

        <v-card-text>
          {{ $t('components.confirm_register.sub_title') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            id="cancel"
            color="green darken-1"
            text
            @click="response(false)"
          >
            {{ $t('components.confirm_register.cancel') }}
          </v-btn>
          <v-btn
            id="next"
            color="green darken-1"
            text
            @click="response(true)"
          >
            {{ $t('components.confirm_register.next') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    value(value) {
      this.dialog = value;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('change', this.dialog);
    },
    response(value) {
      this.$emit('confirmationResponse', value);
      this.dialog = false;
    },
  },
};
</script>
